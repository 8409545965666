import i18n from 'i18n'

import { getRemixFontsList } from 'common/constants/fonts'

import icon_addText from './i/add_text.svg'
import icon_addImg from './i/add_img.svg'
import icon_addLogo from './i/add_logo.svg'
import icon_addVideo from './i/add_video.svg'
import icon_addAudio from './i/add_audio.svg'
import icon_addCta from './i/add_cta.svg'
import iconUnlocked from './i/unlocked.svg'
import iconLocked from './i/locked.svg'
import icon_embedIframe from './i/embedIframe_icon.svg'
import icon_cover from './i/cover.svg'
import icon_textAndImage from './i/textAndImage.svg'

import icon_cookies from './i/cookies_icon.svg'
import icon_cookies_active from './i/cookies_icon--active.svg'
import icon_triviaQuiz from './i/triviaQuiz_icon.svg'
import icon_triviaQuiz_active from './i/triviaQuiz_icon--active.svg'
import icon_leadForm from './i/leadForm_icon.svg'
import icon_leadForm_active from './i/leadForm_icon--active.svg'
import icon_personalityQuiz from './i/personalityQuiz_icon.svg'
import icon_personalityQuiz_active from './i/personalityQuiz_icon--active.svg'
import icon_memory from './i/memory_icon.svg'
import icon_memory_active from './i/memory_icon--active.svg'
import icon_interactiveImage from './i/interactiveImage_icon.svg'
import icon_interactiveImage_active from './i/interactiveImage_icon--active.svg'
import icon_hiddenObjects from './i/hiddenObjects_icon.svg'
import icon_hiddenObjects_active from './i/hiddenObjects_icon--active.svg'
import icon_horoscope from './i/horoscope_icon.svg'
import icon_horoscope_active from './i/horoscope_icon--active.svg'
import icon_flipCards from './i/flipCards_icon.svg'
import icon_flipCards_active from './i/flipCards_icon--active.svg'
import icon_thenNow from './i/thenNow_icon.svg'
import icon_thenNow_active from './i/thenNow_icon--active.svg'
import icon_timeline from './i/timeline_icon.svg'
import icon_timeline_active from './i/timeline_icon--active.svg'
import icon_findPair from './i/findPair_icon.svg'
import icon_findPair_active from './i/findPair_icon--active.svg'
import icon_slideshow from './i/slideshow_icon.svg'
import icon_slideshow_active from './i/slideshow_icon--active.svg'
import icon_puzzle from './i/puzzle_icon.svg'
import icon_puzzle_active from './i/puzzle_icon--active.svg'
import icon_treasureHunt from './i/treasureHunt_icon.svg'
import icon_treasureHunt_active from './i/treasureHunt_icon--active.svg'
import icon_matching from './i/matching_icon.svg'
import icon_matching_active from './i/matching_icon--active.svg'
import icon_question from './i/question_icon.svg'
import icon_question_active from './i/question_icon--active.svg'
import icon_wheelSpin from './i/wheelSpin_icon.svg'
import icon_wheelSpin_active from './i/wheelSpin_icon--active.svg'
import icon_crossword from './i/crossword_icon.svg'
import icon_crossword_active from './i/crossword_icon--active.svg'
import icon_slidingPuzzle from './i/slidingPuzzle_icon.svg'
import icon_slidingPuzzle_active from './i/slidingPuzzle_icon--active.svg'
import icon_rankBattle from './i/rankBattle_icon.svg'
import icon_rankBattle_active from './i/rankBattle_icon--active.svg'

import BLOCK from 'common/constants/BlockTypes/BlocksEnum'
import { DEFAULT_IMAGE_URL, AUDIO_PLAYER_CONTROLS, DEFAULT_MULTIPLAYER_BG } from 'common/constants/constants'
import { IMAGE_SIZES } from 'common/constants/image'
import { getUniqueId } from 'common/utils/getUniqueId'
import { ASSET_CONTENT_TYPE } from 'components/AssetManager/utils/constants'

import { LOCALES } from 'i18n/languages'

import { TIME_IS_UP_INIT_STATE } from './Modals/TimeIsUpModal/utils/constants'
import { MODAL_NAMES } from './Modals/ModalController'
import { CONTROLS_ENUM } from './Panels/Control/constants'

import {
    getInitialLeadFormStructureForEmbeddedMode,
    getInitialLeadFormStructureForIndependentMode,
} from './Modals/LeadFormModal/utils'
import { getInitialYandexMetricStructure } from './Modals/YandexMetricModal/utils'

export const STATIC_BLOCKS = [
    {
        t: BLOCK.text,
        icon: icon_addText,
        label: i18n.t('Text'),
        labelForRightPanel: i18n.t('Text'),
    },
    {
        t: BLOCK.image,
        icon: icon_addImg,
        label: i18n.t('Image'),
        labelForRightPanel: i18n.t('Image'),
    },
    {
        t: BLOCK.logo,
        icon: icon_addLogo,
        label: i18n.t('Logo'),
        labelForRightPanel: i18n.t('Logo'),
    },
    {
        t: BLOCK.youtubeVideo,
        icon: icon_addVideo,
        label: i18n.t('Youtube video'),
        labelForRightPanel: i18n.t('Youtube video'),
    },
    {
        t: BLOCK.button,
        icon: icon_addCta,
        label: i18n.t('Button'),
        labelForRightPanel: i18n.t('Button'),
    },
    {
        t: BLOCK.audio,
        icon: icon_addAudio,
        label: i18n.t('Audio'),
        labelForRightPanel: i18n.t('Audio'),
        contentType: ASSET_CONTENT_TYPE.audio,
    },
    {
        t: BLOCK.cover,
        icon: icon_cover,
        label: i18n.t('Cover'),
        labelForRightPanel: i18n.t('Cover'),
        isHasTemplates: true,
        templatesTitle: i18n.t('Cover templates'),
    },
    {
        t: BLOCK.textAndImage,
        icon: icon_textAndImage,
        label: i18n.t('Text and Image'),
        labelForRightPanel: i18n.t('Text and Image'),
        isHasTemplates: true,
        templatesTitle: i18n.t('Text and Image templates'),
    },
]

export const INTERACTIVE_BLOCKS = [
    {
        t: BLOCK.question,
        icon: icon_question,
        iconActive: icon_question_active,
        label: i18n.t('Question'),
        labelForRightPanel: i18n.t('Question settings'),
        color: '#0075FF',
        isHasTemplates: true,
        templatesTitle: i18n.t('Question templates'),
        isMultiplayerBlock: true,
        isOnlyMultiplayerBlock: true,
        hint: i18n.language === LOCALES.EN ? i18n.t('try me ') : i18n.t('new'),
    },
    {
        t: BLOCK.quiz,
        icon: icon_triviaQuiz,
        iconActive: icon_triviaQuiz_active,
        label: i18n.t('Quiz'),
        labelForRightPanel: i18n.t('Quiz settings'),
        color: '#FF5656',
        isHasTemplates: true,
        templatesTitle: i18n.t('Quiz templates'),
        isMultiplayerBlock: true,
    },
    {
        t: BLOCK.personalityQuiz,
        icon: icon_personalityQuiz,
        iconActive: icon_personalityQuiz_active,
        label: i18n.t('Personality'),
        labelForRightPanel: i18n.t('Personality Quiz settings'),
        color: '#9560E6',
        isHasTemplates: true,
        templatesTitle: i18n.t('Personality Quiz templates'),
    },
    {
        t: BLOCK.memoryCards,
        icon: icon_memory,
        iconActive: icon_memory_active,
        label: i18n.t('Memory'),
        labelForRightPanel: i18n.t('Memory settings'),
        color: '#4F32CF',
        isHasTemplates: true,
        templatesTitle: i18n.t('Memory templates'),
        isMultiplayerBlock: true,
    },
    {
        t: BLOCK.matching,
        icon: icon_matching,
        iconActive: icon_matching_active,
        label: i18n.t('Match Up'),
        labelForRightPanel: i18n.t('Match Up settings'),
        color: '#C72468',
        isHasTemplates: true,
        templatesTitle: i18n.t('Match Up templates'),
        isMultiplayerBlock: true,
    },
    {
        t: BLOCK.rankBattle,
        icon: icon_rankBattle,
        iconActive: icon_rankBattle_active,
        label: i18n.t('Rank Battle'),
        labelForRightPanel: i18n.t('Rank Battle settings'),
        color: '#2970FB',
        isHasTemplates: true,
        templatesTitle: i18n.t('Rank Battle templates'),
        hint: i18n.t('new'),
    },
    {
        t: BLOCK.wheelSpin,
        icon: icon_wheelSpin,
        iconActive: icon_wheelSpin_active,
        label: i18n.t('Spin the Wheel'),
        labelForRightPanel: i18n.t('Wheel settings'),
        color: '#FF7676',
        isHasTemplates: true,
        templatesTitle: i18n.t('Wheel templates'),
    },
    {
        t: BLOCK.crossword,
        icon: icon_crossword,
        iconActive: icon_crossword_active,
        label: i18n.t('Crossword'),
        labelForRightPanel: i18n.t('Crossword settings'),
        color: '#34C96D',
        isHasTemplates: true,
        templatesTitle: i18n.t('Crossword templates'),
    },
    {
        t: BLOCK.slidingPuzzle,
        icon: icon_slidingPuzzle,
        iconActive: icon_slidingPuzzle_active,
        label: i18n.t('Sliding Puzzle'),
        labelForRightPanel: i18n.t('Sliding Puzzle settings'),
        color: '#CCC62A',
        isHasTemplates: true,
        templatesTitle: i18n.t('Sliding Puzzle templates'),
    },
    {
        t: BLOCK.findPair,
        icon: icon_findPair,
        iconActive: icon_findPair_active,
        label: i18n.t('Find One Pair'),
        labelForRightPanel: i18n.t('Find One pair settings'),
        color: '#99C934',
        isHasTemplates: true,
        templatesTitle: i18n.t('Find One pair templates'),
        isMultiplayerBlock: true,
    },
    {
        t: BLOCK.slideshow,
        icon: icon_slideshow,
        iconActive: icon_slideshow_active,
        label: i18n.t('Slideshow'),
        labelForRightPanel: i18n.t('Slideshow'),
        color: '#24BFD8',
        isHasTemplates: true,
        templatesTitle: i18n.t('Slideshow templates'),
    },
    {
        t: BLOCK.leadForm,
        icon: icon_leadForm,
        iconActive: icon_leadForm_active,
        label: i18n.t('Lead form'),
        labelForRightPanel: i18n.t('Lead form settings'),
        color: '#5EA1F0',
        isHasTemplates: true,
        templatesTitle: i18n.t('Lead form templates'),
        isHideFromMultiplayer: true,
    },
    {
        t: BLOCK.flipCards,
        icon: icon_flipCards,
        iconActive: icon_flipCards_active,
        label: i18n.t('Flip Card'),
        labelForRightPanel: i18n.t('Flip Card'),
        color: '#E2CCAB',
    },
    {
        t: BLOCK.thenNow,
        icon: icon_thenNow,
        iconActive: icon_thenNow_active,
        label: i18n.t('Then & Now'),
        labelForRightPanel: i18n.t('Then & Now'),
        color: '#C86445',
        isHasTemplates: true,
        templatesTitle: i18n.t('Then & Now templates'),
    },
    {
        t: BLOCK.timeline,
        icon: icon_timeline,
        iconActive: icon_timeline_active,
        label: i18n.t('Timeline'),
        labelForRightPanel: i18n.t('Timeline'),
        color: '#5A29B0',
    },
    {
        t: BLOCK.cookies,
        icon: icon_cookies,
        iconActive: icon_cookies_active,
        label: i18n.t('Cookies'),
        labelForRightPanel: i18n.t('Cookies settings'),
        color: '#FF9C40',
        isHasTemplates: true,
        templatesTitle: i18n.t('Cookies templates'),
    },
    {
        t: BLOCK.horoscope,
        icon: icon_horoscope,
        iconActive: icon_horoscope_active,
        label: i18n.t('Horoscope'),
        labelForRightPanel: i18n.t('Horoscope settings'),
        color: '#D372CA',
        isHasTemplates: true,
        templatesTitle: i18n.t('Horoscope templates'),
    },
    {
        t: BLOCK.interactiveImage,
        icon: icon_interactiveImage,
        iconActive: icon_interactiveImage_active,
        label: i18n.t('Interactive image'),
        labelForRightPanel: i18n.t('Interactive image'),
        color: '#FFBE5C',
        isHasTemplates: true,
        templatesTitle: i18n.t('Interactive image templates'),
    },
    {
        t: BLOCK.hiddenObjects,
        icon: icon_hiddenObjects,
        iconActive: icon_hiddenObjects_active,
        label: i18n.t('Hidden objects'),
        labelForRightPanel: i18n.t('Hidden objects'),
        color: '#AE72D3',
        isHasTemplates: true,
        templatesTitle: i18n.t('Hidden objects templates'),
        isMultiplayerBlock: true,
    },
    {
        t: BLOCK.puzzle,
        icon: icon_puzzle,
        iconActive: icon_puzzle_active,
        label: i18n.t('Puzzle'),
        isHasTemplates: true,
        labelForRightPanel: i18n.t('Puzzle'),
        color: '#46BEBE',
        isMultiplayerBlock: true,
    },
    {
        t: BLOCK.treasureHunt,
        icon: icon_treasureHunt,
        iconActive: icon_treasureHunt_active,
        label: i18n.t('Treasure Hunt'),
        isHasTemplates: true,
        labelForRightPanel: i18n.t('Treasure Hunt'),
        color: '#64DCF7',
        isMultiplayerBlock: true,
    },
]

export const OTHERS_BLOCKS = [
    {
        t: BLOCK.embedIframe,
        icon: icon_embedIframe,
        label: i18n.t('Embed iframe'),
        labelForRightPanel: i18n.t('Embed iframe'),
    },
]

export const BLOCK_DICTIONARY = [...STATIC_BLOCKS, ...INTERACTIVE_BLOCKS, ...OTHERS_BLOCKS].reduce(
    (dictionary, block) => {
        dictionary[block.t] = block
        return dictionary
    },
    {},
)

export const SCHEMA_KEY = {
    h: 'h',
    d: 'd',
    i: 'i',
    blink: 'blink',
    btext: 'btext',
    isEnableRating: 'isEnableRating',
    numberOfAttempts: 'numberOfAttempts',
    isHideLeaderboard: 'isHideLeaderboard',
    legalStatement: 'legalStatement',
    isShowRatingEmail: 'isShowRatingEmail',
    enableTimer: 'enableTimer',
    enableStars: 'enableStars',
    starsTimeList: 'starsTimeList',
    timerType: 'timerType',
    countdownTime: 'countdownTime',
    timeIsUpScreen: 'timeIsUpScreen',
    passwordList: 'passwordList',
    isActionButton: 'isActionButton',
    actionButtonText: 'actionButtonText',
    actionButtonLink: 'actionButtonLink',
    callToActionEnabled: 'callToActionEnabled',
    callToActionText: 'callToActionText',
    callToActionLink: 'callToActionLink',
    isRedirectEnabled: 'isRedirectEnabled',
    redirectTargetLink: 'redirectTargetLink',
    redirectTimeout: 'redirectTimeout',
    isShowLeadForm: 'isShowLeadForm',
    leadFormStruct: 'leadFormStruct',
    integrationMetadata: 'integrationMetadata',
    privacyDemo: 'privacyDemo',
    gamificationDemo: 'gamificationDemo',
    colorsPalette: 'colorsPalette',
    isTransparentBackground: 'isTransparentBackground',
    wP_bg: 'wP_bg',
    text: 'text',
    url: 'url',
    imageSize: 'imageSize',
    width: 'width',
    position: 'position',
    blur: 'blur',
    padding: 'padding',
    link: 'link',
    embedCode: 'embedCode',
    imageProportions: 'imageProportions',
    frontSrc: 'frontSrc',
    backSrc: 'backSrc',
    frontText: 'frontText',
    frontColor: 'frontColor',
    backText: 'backText',
    backColor: 'backColor',
    href: 'href',
    brad: 'brad',
    bc: 'bc',
    pulse: 'pulse',
    pins: 'pins',
    count: 'count',
    bimg: 'bimg',
    pcl: 'pcl',
    psize: 'psize',
    pimg: 'pimg',
    btcolor: 'btcolor',
    suct: 'suct',
    sucd: 'sucd',
    sucImg: 'sucImg',
    sucBlink: 'sucBlink',
    sucBtext: 'sucBtext',
    isShowCover: 'isShowCover',
    coverHeader: 'coverHeader',
    coverBtnText: 'coverBtnText',
    struct: 'struct',
    colorTheme: 'colorTheme',
    progressBar: 'progressBar',
    showScores: 'showScores',
    shuffleQuestions: 'shuffleQuestions',
    shuffleAnswers: 'shuffleAnswers',
    notMarkCorrectAnswers: 'notMarkCorrectAnswers',
    backgroundImage: 'backgroundImage',
    isRepeatBackgroundImage: 'isRepeatBackgroundImage',
    shuffleCards: 'shuffleCards',
    leftSrc: 'leftSrc',
    rightSrc: 'rightSrc',
    numberedCardBacks: 'numberedCardBacks',
    numberedCardBacksType: 'numberedCardBacksType',
    timeoutClosingCards: 'timeoutClosingCards',
    showToMemorize: 'showToMemorize',
    showToMemorizeTime: 'showToMemorizeTime',
    markTitle: 'markTitle',
    markSubtitle: 'markSubtitle',
    imageUrl: 'imageUrl',
    imageDescription: 'imageDescription',
    isLoop: 'isLoop',
    isAutoPlay: 'isAutoPlay',
    isMuted: 'isMuted',
    progressJumpSteps: 'progressJumpSteps',
    firstLineBarControls: 'firstLineBarControls',
    secondLineBarControls: 'secondLineBarControls',
    height: 'height',
    maxWidth: 'maxWidth',
    bg: 'bg',
    multiplayerBackgroundImage: 'multiplayerBackgroundImage',
    customTranslations: 'customTranslations',
    customStyles: 'customStyles',
    rememberEmail: 'rememberEmail',
    fontFamily: 'fontFamily',
    leadFormFontFamily: 'leadFormFontFamily',
    colorScheme: 'colorScheme',
    isMarkCorrectLetters: 'isMarkCorrectLetters',
    isMarkCorrectWords: 'isMarkCorrectWords',
    correctColor: 'correctColor',
    incorrectColor: 'incorrectColor',
    isHighlightCorrect: 'isHighlightCorrect',
    letterColor: 'letterColor',
    correctLetterColor: 'correctLetterColor',
    isShowShuffleButton: 'isShowShuffleButton',
    isOpenLinkInNewTab: 'isOpenLinkInNewTab',
    isHideRestartButton: 'isHideRestartButton',
    isOneTimeVote: 'isOneTimeVote',
    backgroundColor: 'backgroundColor',
    leadFormBackgroundColor: 'leadFormBackgroundColor',
    textColor: 'textColor',
    yandexMetric: 'yandexMetric',
    darkenBackground: 'darkenBackground',
    isShowLogotype: 'isShowLogotype',
    logotypeUrl: 'logotypeUrl',
    logotypeScale: 'logotypeScale',
    logotypePosition: 'logotypePosition',
    isShowButton: 'isShowButton',
    buttonUrl: 'buttonUrl',
    buttonBackgroundColor: 'buttonBackgroundColor',
    buttonBorderRadius: 'buttonBorderRadius',
    buttonPosition: 'buttonPosition',
    textPosition: 'textPosition',
    buttonText: 'buttonText',
}

const getPinFields = () => ({
    [SCHEMA_KEY.h]: {
        label: i18n.t('Title'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.TextInput,
    },
    [SCHEMA_KEY.d]: {
        label: i18n.t('Description'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.TextArea,
    },
    [SCHEMA_KEY.i]: {
        label: i18n.t('Image'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.UploadButton,
    },
    [SCHEMA_KEY.blink]: {
        label: i18n.t('Button link'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.InputWithModal,
        payload: {
            modalName: MODAL_NAMES.selectInternalPageModal,
        },
    },
    [SCHEMA_KEY.btext]: {
        label: i18n.t('Button text'),
        type: 'string',
        default: 'Ok',
        control: CONTROLS_ENUM.TextInput,
    },
})
const getGamificationFields = () => ({
    [SCHEMA_KEY.isEnableRating]: {
        label: i18n.t('Enable player ratings'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.Checkbox,
    },
    [SCHEMA_KEY.numberOfAttempts]: {
        label: i18n.t('Number of attempts'),
        type: 'number',
        default: '',
        canBeEmptyString: true,
        min: 1,
        max: 1000000,
        control: CONTROLS_ENUM.TextInput,
    },
    [SCHEMA_KEY.isHideLeaderboard]: {
        label: i18n.t('Hide leaderboard from users'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.Checkbox,
    },
    [SCHEMA_KEY.legalStatement]: {
        label: i18n.t('Links to service policies (html)'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.TextArea,
        maxLength: 600,
    },
    [SCHEMA_KEY.isShowRatingEmail]: {
        label: i18n.t('Ask user email'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.Checkbox,
    },
    [SCHEMA_KEY.enableTimer]: {
        label: i18n.t('Enable timer'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.Checkbox,
    },
    [SCHEMA_KEY.enableStars]: {
        label: i18n.t('Show stars on result page'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.Checkbox,
    },
    [SCHEMA_KEY.starsTimeList]: {
        label: i18n.t('Values in seconds'),
        type: 'string',
        default: '45, 30, 15',
        canBeUndefined: false,
        control: CONTROLS_ENUM.TextInput,
    },
    [SCHEMA_KEY.timerType]: {
        type: 'object',
        default: {
            value: 'stopwatch',
            label: 'Classic timer',
        },
        control: CONTROLS_ENUM.RadioButton,
        payload: {
            flexDirection: 'row',
            items: [
                {
                    value: 'stopwatch',
                    label: i18n.t('Classic timer'),
                },
                {
                    value: 'countdown',
                    label: i18n.t('Countdown'),
                },
            ],
        },
    },
    [SCHEMA_KEY.countdownTime]: {
        label: i18n.t('Value in seconds'),
        type: 'number',
        default: 30,
        min: 1,
        max: 1000000,
        control: CONTROLS_ENUM.TextInput,
    },
    [SCHEMA_KEY.timeIsUpScreen]: {
        label: i18n.t('Customize the “Time is up” page'),
        type: 'object',
        default: TIME_IS_UP_INIT_STATE,
        control: CONTROLS_ENUM.LinkWithModal,
        payload: {
            modalName: MODAL_NAMES.timeIsUpModal,
        },
    },
})
const getPrivacyFields = () => ({
    [SCHEMA_KEY.passwordList]: {
        label: i18n.t('Privacy'),
        type: 'array',
        default: [],
        control: CONTROLS_ENUM.ArrayWithModal,
        payload: {
            modalName: MODAL_NAMES.privacySettingsModal,
            values: {
                isEmpty: {
                    icon: iconUnlocked,
                    label: i18n.t('No restrictions'),
                },
                isFilled: {
                    icon: iconLocked,
                    label: i18n.t('Password access'),
                },
            },
        },
    },
})
const getActionButtonFields = () => ({
    [SCHEMA_KEY.isActionButton]: {
        label: i18n.t('Call to action button'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.Checkbox,
    },
    [SCHEMA_KEY.actionButtonText]: {
        label: i18n.t('Button text'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.TextInput,
    },
    [SCHEMA_KEY.actionButtonLink]: {
        label: i18n.t('Link'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.InputWithModal,
        payload: {
            modalName: MODAL_NAMES.selectInternalPageModal,
        },
    },
    [SCHEMA_KEY.isOpenLinkInNewTab]: {
        label: i18n.t('Open in new tab'),
        type: 'boolean',
        default: true,
        control: CONTROLS_ENUM.Checkbox,
    },
})
const getOldActionButtonFields = () => ({
    [SCHEMA_KEY.callToActionEnabled]: {
        label: i18n.t('Call to action button'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.Checkbox,
    },
    [SCHEMA_KEY.callToActionText]: {
        label: i18n.t('Button text'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.TextInput,
    },
    [SCHEMA_KEY.callToActionLink]: {
        label: i18n.t('Link'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.InputWithModal,
        payload: {
            modalName: MODAL_NAMES.selectInternalPageModal,
        },
    },
    [SCHEMA_KEY.isOpenLinkInNewTab]: {
        label: i18n.t('Open in new tab'),
        type: 'boolean',
        default: true,
        control: CONTROLS_ENUM.Checkbox,
    },
})
const getAutoRedirectFields = label => ({
    [SCHEMA_KEY.isRedirectEnabled]: {
        label: label || i18n.t('Redirect from result page'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.Checkbox,
    },
    [SCHEMA_KEY.redirectTargetLink]: {
        label: i18n.t('Redirect to (link)'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.TextInput,
    },
    [SCHEMA_KEY.redirectTimeout]: {
        label: i18n.t('Seconds before redirect'),
        type: 'number',
        default: 4,
        min: 1,
        max: 100,
        control: CONTROLS_ENUM.TextInput,
    },
})
const getLeadFormFields = () => ({
    [SCHEMA_KEY.isShowLeadForm]: {
        label: i18n.t('Show lead form'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.Checkbox,
    },
    [SCHEMA_KEY.leadFormStruct]: {
        label: i18n.t('Customize'),
        type: 'object',
        default: getInitialLeadFormStructureForEmbeddedMode(),
        control: CONTROLS_ENUM.LinkWithModal,
        payload: {
            modalName: MODAL_NAMES.leadFormModal,
        },
    },
    [SCHEMA_KEY.leadFormBackgroundColor]: {
        label: i18n.t('Background color'),
        type: 'string',
        default: '#fff',
        control: CONTROLS_ENUM.ColorPicker,
    },
    [SCHEMA_KEY.leadFormFontFamily]: {
        label: i18n.t('Font family'),
        type: 'string',
        default: 'Roboto',
        control: CONTROLS_ENUM.Select,
        payload: {
            items: getRemixFontsList(true),
            useValueAsFontFamily: true,
            getOptionFromValue: value => ({
                value,
                label: value,
            }),
            setValueFromOption: option => option.value,
        },
    },
})
const getSdkIntegrationFields = () => ({
    [SCHEMA_KEY.integrationMetadata]: {
        label: i18n.t('Metadata for SDK integration'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.TextArea,
        maxLength: 600,
    },
})
const getPrivacyGamificationDemoFields = () => ({
    [SCHEMA_KEY.privacyDemo]: {
        label: i18n.t('Privacy'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.DemoWithModal,
        payload: {
            modalName: MODAL_NAMES.UpgradeDialog,
        },
    },
    [SCHEMA_KEY.gamificationDemo]: {
        label: i18n.t('Gamification'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.DemoWithModal,
        payload: {
            modalName: MODAL_NAMES.UpgradeDialog,
            description: i18n.t('Add a leaderboard to your game and create a competition!'),
        },
    },
})
const getRestartFields = label => ({
    [SCHEMA_KEY.isHideRestartButton]: {
        label: label || i18n.t('Hide restart button'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.Checkbox,
    },
})
const getYandexMetricFields = blockId => ({
    [SCHEMA_KEY.yandexMetric]: {
        label: 'Настроить',
        type: 'object',
        default: getInitialYandexMetricStructure(blockId),
        control: CONTROLS_ENUM.LinkWithModal,
        payload: {
            modalName: MODAL_NAMES.yandexMetricModal,
            name: i18n.t('Yandex Metric'),
        },
    },
})

const COLOR_SCHEMES = [
    {
        value: 'MONOCHROMATIC',
        label: i18n.t('Monochromatic'),
    },
    {
        value: 'ANALOGOUS',
        label: i18n.t('Analogous'),
    },
    {
        value: 'TRIADIC',
        label: i18n.t('Triadic'),
    },
    {
        value: 'SPLIT_COMPLEMENTARY',
        label: i18n.t('Split Complementary'),
    },
]
const IMAGE_PROPORTIONS = [
    {
        value: '1|1',
        label: '1:1',
    },
    {
        value: '5|4',
        label: '5:4',
    },
    {
        value: '4|3',
        label: '4:3',
    },
    {
        value: '3|2',
        label: '3:2',
    },
    {
        value: '16|9',
        label: '16:9',
    },
]
const EXTENDED_IMAGE_PROPORTIONS = [
    {
        value: '1|1',
        label: '1:1',
    },
    {
        value: '5|4',
        label: '5:4',
    },
    {
        value: '4|3',
        label: '4:3',
    },
    {
        value: '3|2',
        label: '3:2',
    },
    {
        value: '16|9',
        label: '16:9',
    },
    {
        value: '2|1',
        label: '2:1',
    },
    {
        value: '4|1',
        label: '4:1',
    },
]
const POSITIONS = [
    {
        value: 'left',
        label: i18n.t('Left'),
    },
    {
        value: 'right',
        label: i18n.t('Right'),
    },
]
const EXTENDED_POSITIONS = [
    {
        value: 'left',
        label: i18n.t('Left'),
    },
    {
        value: 'center',
        label: i18n.t('Center'),
    },
    {
        value: 'right',
        label: i18n.t('Right'),
    },
]

export const APP_SCHEMA = {
    [SCHEMA_KEY.colorTheme]: {
        label: i18n.t('Color theme'),
        type: 'string',
        default: '#2990fb',
        control: CONTROLS_ENUM.ColorPicker,
    },
    [SCHEMA_KEY.maxWidth]: {
        label: i18n.t('Max width'),
        type: 'number',
        default: 800,
        min: 320,
        max: 4320, // 8k
        control: CONTROLS_ENUM.TextInput,
    },
    [SCHEMA_KEY.isTransparentBackground]: {
        label: i18n.t('Transparent background'),
        type: 'boolean',
        default: false,
        control: CONTROLS_ENUM.Checkbox,
    },
    [SCHEMA_KEY.bg]: {
        label: i18n.t('Background color'),
        type: 'string',
        default: '#fff',
        control: CONTROLS_ENUM.ColorPicker,
    },
    [SCHEMA_KEY.multiplayerBackgroundImage]: {
        label: i18n.t('Background image'),
        type: 'string',
        default: DEFAULT_MULTIPLAYER_BG,
        control: CONTROLS_ENUM.UploadButton,
    },
    [SCHEMA_KEY.backgroundImage]: {
        label: i18n.t('Background image'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.UploadButton,
    },
    // [SCHEMA_KEY.isRepeatBackgroundImage]: {
    //     label: i18n.t('Repeat background image'),
    //     type: 'boolean',
    //     default: false,
    //     control: CONTROLS_ENUM.Checkbox,
    // },
    [SCHEMA_KEY.customTranslations]: {
        label: i18n.t('Custom translations'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.LinkWithModal,
        payload: {
            modalName: MODAL_NAMES.customTranslationsModal,
        },
    },
    [SCHEMA_KEY.customStyles]: {
        label: i18n.t('Custom styles'),
        type: 'string',
        default: '',
        control: CONTROLS_ENUM.CssCodeEditor,
    },
}

export const BLOCK_SCHEMA = {
    [BLOCK.text]: {
        [SCHEMA_KEY.isTransparentBackground]: {
            label: i18n.t('Transparent background'),
            type: 'boolean',
            default: true,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.wP_bg]: {
            label: i18n.t('Background color'),
            type: 'string',
            default: '#fff',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.text]: {
            type: 'string',
            default: `<p><span class="ql-font-Roboto" style="font-size: 14px; line-height: 1.5;">${i18n.t(
                'Tell your story…',
            )}</span></p>`,
            minLength: 0,
            maxLength: 16000,
        },
    },
    [BLOCK.image]: {
        [SCHEMA_KEY.url]: {
            label: i18n.t('Image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
            payload: {
                isCanDelete: false,
            },
        },
        [SCHEMA_KEY.imageSize]: {
            label: i18n.t('Image size'),
            type: 'object',
            default: IMAGE_SIZES.fullWidth,
            control: CONTROLS_ENUM.Select,
            payload: {
                items: [IMAGE_SIZES.fullWidth, IMAGE_SIZES.compact, IMAGE_SIZES.custom],
            },
        },
        [SCHEMA_KEY.width]: {
            label: `${i18n.t('Scale')} (%)`,
            type: 'number',
            default: 100,
            min: 0,
            max: 100,
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.position]: {
            label: i18n.t('Position'),
            type: 'object',
            default: {
                value: 'center',
                label: i18n.t('Center'),
            },
            control: CONTROLS_ENUM.RadioButton,
            payload: {
                flexDirection: 'row',
                items: EXTENDED_POSITIONS,
            },
        },
        [SCHEMA_KEY.blur]: {
            label: i18n.t('Interactive blur'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
    },
    [BLOCK.logo]: {
        [SCHEMA_KEY.url]: {
            label: i18n.t('Image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
            payload: {
                isCanDelete: false,
            },
        },
        [SCHEMA_KEY.width]: {
            label: `${i18n.t('Scale')} (%)`,
            type: 'number',
            default: 20,
            min: 0,
            max: 100,
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.padding]: {
            label: i18n.t('Padding'),
            type: 'string',
            default: '0 0 0 0',
            control: CONTROLS_ENUM.PaddingEditor,
        },
        [SCHEMA_KEY.position]: {
            label: i18n.t('Position'),
            type: 'object',
            default: {
                value: 'center',
                label: i18n.t('Center'),
            },
            control: CONTROLS_ENUM.RadioButton,
            payload: {
                flexDirection: 'row',
                items: EXTENDED_POSITIONS,
            },
        },
        [SCHEMA_KEY.link]: {
            label: i18n.t('Link'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.InputWithModal,
            payload: {
                modalName: MODAL_NAMES.selectInternalPageModal,
            },
        },
    },
    [BLOCK.embedInteractyProject]: {
        [SCHEMA_KEY.embedCode]: {
            label: i18n.t('Embed code'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.TextArea,
        },
    },
    [BLOCK.flipCards]: {
        [SCHEMA_KEY.imageProportions]: {
            label: i18n.t('Image proportions'),
            type: 'object',
            default: {
                value: '3|2',
                label: '3:2',
            },
            control: CONTROLS_ENUM.Select,
            payload: {
                items: IMAGE_PROPORTIONS,
            },
        },
        [SCHEMA_KEY.frontSrc]: {
            label: i18n.t('Front image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
        },
        [SCHEMA_KEY.backSrc]: {
            label: i18n.t('Back image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
        },
        [SCHEMA_KEY.frontText]: {
            type: 'string',
            default: `<p class="ql-align-center"><span class="ql-font-Roboto" style="font-size: 24px; line-height: 1.5; color: rgb(255, 255, 255);">${i18n.t(
                'Front text',
            )}</span></p>`,
            minLength: 0,
            maxLength: 16000,
        },
        [SCHEMA_KEY.frontColor]: {
            label: i18n.t('Front color'),
            type: 'string',
            default: '#b3d7fc',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.backText]: {
            type: 'string',
            default: `<p class="ql-align-center"><span class="ql-font-Roboto" style="font-size: 24px; line-height: 1.5; color: rgb(255, 255, 255);">${i18n.t(
                'Back text',
            )}</span></p>`,
            minLength: 0,
            maxLength: 16000,
        },
        [SCHEMA_KEY.backColor]: {
            label: i18n.t('Back color'),
            type: 'string',
            default: '#69b1fc',
            control: CONTROLS_ENUM.ColorPicker,
        },
    },
    [BLOCK.youtubeVideo]: {
        [SCHEMA_KEY.embedCode]: {
            label: i18n.t('Embed code'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.TextArea,
        },
    },
    [BLOCK.button]: {
        [SCHEMA_KEY.href]: {
            label: i18n.t('Link'),
            type: 'string',
            default: 'https://interacty.me',
            control: CONTROLS_ENUM.InputWithModal,
            payload: {
                modalName: MODAL_NAMES.selectInternalPageModal,
            },
        },
        [SCHEMA_KEY.brad]: {
            label: i18n.t('Border radius'),
            type: 'number',
            default: 4,
            min: 0,
            max: 99,
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.bc]: {
            label: i18n.t('Background color'),
            type: 'string',
            default: '#2990fb',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.text]: {
            type: 'string',
            default:
                '<p class="ql-align-center"><span class="ql-font-Roboto" style="font-size: 20px; line-height: 1.5; color: rgb(255, 255, 255);">Click</span></p>',
            minLength: 0,
            maxLength: 16000,
        },
        [SCHEMA_KEY.pulse]: {
            label: i18n.t('Pulse'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
    },
    [BLOCK.interactiveImage]: {
        [SCHEMA_KEY.pins]: {
            type: 'array',
            default: [],
        },
        [SCHEMA_KEY.count]: {
            label: i18n.t('Number of tags'),
            type: 'number',
            default: 3,
            min: 0,
            max: 50,
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.bimg]: {
            label: i18n.t('Image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
        },
        [SCHEMA_KEY.pcl]: {
            label: i18n.t('Tag color'),
            type: 'string',
            default: '#2990fb',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.psize]: {
            label: i18n.t('Tag size'),
            type: 'number',
            default: 24,
            min: 4,
            max: 100,
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.pimg]: {
            label: i18n.t('Tag image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
        },
        [SCHEMA_KEY.btcolor]: {
            label: i18n.t('Button text color'),
            type: 'string',
            default: '#fff',
            control: CONTROLS_ENUM.ColorPicker,
        },
    },
    [BLOCK.interactiveImage + '.pins']: getPinFields(),
    [BLOCK.hiddenObjects]: {
        [SCHEMA_KEY.pins]: {
            type: 'array',
            default: [],
        },
        [SCHEMA_KEY.count]: {
            label: i18n.t('Number of hidden tags'),
            type: 'number',
            default: 3,
            min: 1,
            max: 50,
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.bimg]: {
            label: i18n.t('Image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
        },
        [SCHEMA_KEY.pcl]: {
            label: i18n.t('Tag/Button color'),
            type: 'string',
            default: '#2990fb',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.psize]: {
            label: i18n.t('Hidden tag size'),
            type: 'number',
            default: 24,
            min: 4,
            max: 100,
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.pimg]: {
            label: i18n.t('Tag image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
        },
        [SCHEMA_KEY.btcolor]: {
            label: i18n.t('Button text color'),
            type: 'string',
            default: '#fff',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.suct]: {
            label: i18n.t('Header text in the final message'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.sucd]: {
            label: i18n.t('The main body of the final message'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.TextArea,
        },
        [SCHEMA_KEY.sucImg]: {
            label: i18n.t('Image of the final message'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
        },
        [SCHEMA_KEY.isActionButton]: {
            label: i18n.t('Call to action button'),
            type: 'boolean',
            default: true,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.sucBlink]: {
            label: i18n.t('Link'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.InputWithModal,
            payload: {
                modalName: MODAL_NAMES.selectInternalPageModal,
            },
        },
        [SCHEMA_KEY.sucBtext]: {
            label: i18n.t('Button text'),
            type: 'string',
            default: 'Ok',
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.isOpenLinkInNewTab]: {
            label: i18n.t('Open in new tab'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.isShowCover]: {
            label: i18n.t('Show cover'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.coverHeader]: {
            label: i18n.t('Header'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.coverBtnText]: {
            label: i18n.t('Button text'),
            type: 'string',
            default: 'Go',
            control: CONTROLS_ENUM.TextInput,
        },
        ...getRestartFields(),
        ...getGamificationFields(),
        ...getPrivacyFields(),
        ...getPrivacyGamificationDemoFields(),
        ...getLeadFormFields(),
        ...getYandexMetricFields(BLOCK.hiddenObjects),
        ...getAutoRedirectFields(),
    },
    [BLOCK.hiddenObjects + '.pins']: getPinFields(),
    [BLOCK.quiz]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#2990fb',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.progressBar]: {
            label: i18n.t('Progress bar'),
            type: 'boolean',
            default: true,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.showScores]: {
            label: i18n.t('Show score on final screen'),
            type: 'boolean',
            default: true,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.shuffleQuestions]: {
            label: i18n.t('Shuffle questions'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.shuffleAnswers]: {
            label: i18n.t('Shuffle answers'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.notMarkCorrectAnswers]: {
            label: i18n.t('Do not mark correct answers'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        ...getRestartFields(),
        ...getLeadFormFields(),
        ...getOldActionButtonFields(),
        ...getAutoRedirectFields(),
        ...getYandexMetricFields(BLOCK.quiz),
    },
    [BLOCK.personalityQuiz]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#2990fb',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.progressBar]: {
            label: i18n.t('Progress bar'),
            type: 'boolean',
            default: true,
            control: CONTROLS_ENUM.Checkbox,
        },
        ...getRestartFields(),
        ...getLeadFormFields(),
        ...getOldActionButtonFields(),
        ...getAutoRedirectFields(),
        ...getYandexMetricFields(BLOCK.personalityQuiz),
    },
    [BLOCK.cookies]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.isTransparentBackground]: {
            label: i18n.t('Transparent background'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#AB9494',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.backgroundImage]: {
            label: i18n.t('Background image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
        },
        [SCHEMA_KEY.fontFamily]: {
            label: i18n.t('Font family'),
            type: 'string',
            default: 'Roboto',
            control: CONTROLS_ENUM.Select,
            payload: {
                items: getRemixFontsList(true),
                useValueAsFontFamily: true,
                getOptionFromValue: value => ({
                    value,
                    label: value,
                }),
                setValueFromOption: option => option.value,
            },
        },
        [SCHEMA_KEY.shuffleCards]: {
            label: i18n.t('Shuffle cards'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        ...getRestartFields(i18n.t('Hide back button')),
        ...getLeadFormFields(),
        ...getOldActionButtonFields(),
        ...getAutoRedirectFields(),
        ...getYandexMetricFields(BLOCK.cookies),
    },
    [BLOCK.horoscope]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.isTransparentBackground]: {
            label: i18n.t('Transparent background'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#AB9494',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.backgroundImage]: {
            label: i18n.t('Background image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
        },
        [SCHEMA_KEY.fontFamily]: {
            label: i18n.t('Font family'),
            type: 'string',
            default: 'Roboto',
            control: CONTROLS_ENUM.Select,
            payload: {
                items: getRemixFontsList(true),
                useValueAsFontFamily: true,
                getOptionFromValue: value => ({
                    value,
                    label: value,
                }),
                setValueFromOption: option => option.value,
            },
        },
        ...getRestartFields(i18n.t('Hide back button')),
        ...getLeadFormFields(),
        ...getOldActionButtonFields(),
        ...getAutoRedirectFields(),
        ...getYandexMetricFields(BLOCK.horoscope),
    },
    [BLOCK.thenNow]: {
        [SCHEMA_KEY.leftSrc]: {
            label: i18n.t('Left image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
        },
        [SCHEMA_KEY.rightSrc]: {
            label: i18n.t('Right image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
        },
        [SCHEMA_KEY.imageProportions]: {
            label: i18n.t('Image proportions'),
            type: 'object',
            default: {
                value: '3|2',
                label: '3:2',
            },
            control: CONTROLS_ENUM.Select,
            payload: {
                items: EXTENDED_IMAGE_PROPORTIONS,
            },
        },
    },
    [BLOCK.memoryCards]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#AB9494',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.numberedCardBacks]: {
            label: i18n.t('Number card backs'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.numberedCardBacksType]: {
            type: 'object',
            default: {
                value: 'option_1',
                label: 'Option 1',
            },
            control: CONTROLS_ENUM.RadioButton,
            payload: {
                flexDirection: 'row',
                items: [
                    {
                        value: 'option_1',
                        label: i18n.t('Option {{number}}', { number: 1 }),
                    },
                    {
                        value: 'option_2',
                        label: i18n.t('Option {{number}}', { number: 2 }),
                    },
                ],
            },
        },
        [SCHEMA_KEY.timeoutClosingCards]: {
            label: i18n.t('Delay before flipping cards (in seconds)'),
            type: 'number',
            default: 1,
            min: 1,
            max: 10,
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.showToMemorize]: {
            label: i18n.t('Show cards before the game start'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.showToMemorizeTime]: {
            label: i18n.t('Time to memorize (in seconds)'),
            type: 'number',
            default: 3,
            min: 1,
            max: 120,
            control: CONTROLS_ENUM.TextInput,
        },
        ...getRestartFields(),
        ...getSdkIntegrationFields(),
        ...getLeadFormFields(),
        ...getGamificationFields(),
        ...getPrivacyFields(),
        ...getActionButtonFields(),
        ...getPrivacyGamificationDemoFields(),
        ...getYandexMetricFields(BLOCK.memoryCards),
        ...getAutoRedirectFields(),
    },
    [BLOCK.timeline]: {
        [SCHEMA_KEY.text]: {
            type: 'string',
            default: `<p><span class="ql-font-Roboto" style="font-size: 16px; line-height: 1.5;">${i18n.t(
                'Start telling your story...',
            )}</span></p>`,
            minLength: 0,
            maxLength: 16000,
        },
        [SCHEMA_KEY.markTitle]: {
            type: 'string',
            default: `${i18n.t('Date')}`,
            minLength: 0,
            maxLength: 200,
        },
        [SCHEMA_KEY.markSubtitle]: {
            type: 'string',
            default: `${i18n.t('Section header')}`,
            minLength: 0,
            maxLength: 500,
        },
        [SCHEMA_KEY.imageUrl]: {
            label: i18n.t('Choose Image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
        },
        [SCHEMA_KEY.imageDescription]: {
            label: i18n.t('Image caption'),
            type: 'string',
            default: '',
            minLength: 0,
            maxLength: 1000,
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.isTransparentBackground]: {
            label: i18n.t('Transparent background'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.wP_bg]: {
            label: i18n.t('Background color'),
            type: 'string',
            default: '#fff',
            control: CONTROLS_ENUM.ColorPicker,
        },
    },
    [BLOCK.leadForm]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: getInitialLeadFormStructureForIndependentMode(),
        },
        [SCHEMA_KEY.fontFamily]: {
            label: i18n.t('Font family'),
            type: 'string',
            default: 'Roboto',
            control: CONTROLS_ENUM.Select,
            payload: {
                items: getRemixFontsList(true),
                useValueAsFontFamily: true,
                getOptionFromValue: value => ({
                    value,
                    label: value,
                }),
                setValueFromOption: option => option.value,
            },
        },
        [SCHEMA_KEY.backgroundColor]: {
            label: i18n.t('Background color'),
            type: 'string',
            default: '#fff',
            control: CONTROLS_ENUM.ColorPicker,
        },
        ...getActionButtonFields(),
    },
    [BLOCK.findPair]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#AB9494',
            control: CONTROLS_ENUM.ColorPicker,
        },
        ...getRestartFields(),
        ...getGamificationFields(),
        ...getPrivacyFields(),
        ...getActionButtonFields(),
        ...getLeadFormFields(),
        ...getPrivacyGamificationDemoFields(),
        ...getAutoRedirectFields(),
        ...getYandexMetricFields(BLOCK.findPair),
        ...getSdkIntegrationFields(),
    },
    [BLOCK.slideshow]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {
                slides: [
                    {
                        id: getUniqueId(),
                        image: DEFAULT_IMAGE_URL,
                        description: '',
                        hasTextOptions: false,
                        header: '',
                        imageCaption: '',
                    },
                ],
            },
        },
        [SCHEMA_KEY.isTransparentBackground]: {
            label: i18n.t('Transparent background'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Background color'),
            type: 'string',
            default: '#fff',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.textColor]: {
            label: i18n.t('Text color'),
            type: 'string',
            default: '#3c3c3c',
            control: CONTROLS_ENUM.ColorPicker,
        },
    },
    [BLOCK.puzzle]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#AB9494',
            control: CONTROLS_ENUM.ColorPicker,
        },
        ...getRestartFields(),
        ...getGamificationFields(),
        ...getPrivacyFields(),
        ...getActionButtonFields(),
        ...getLeadFormFields(),
        ...getPrivacyGamificationDemoFields(),
        ...getYandexMetricFields(BLOCK.puzzle),
        ...getAutoRedirectFields(),
    },
    [BLOCK.treasureHunt]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#AB9494',
            control: CONTROLS_ENUM.ColorPicker,
        },
        ...getRestartFields(),
        ...getActionButtonFields(),
        ...getLeadFormFields(),
        ...getAutoRedirectFields(),
        ...getYandexMetricFields(BLOCK.treasureHunt),
    },
    [BLOCK.matching]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#AB9494',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.shuffleCards]: {
            label: i18n.t('Shuffle cards'),
            type: 'boolean',
            default: true,
            control: CONTROLS_ENUM.Checkbox,
        },
        ...getRestartFields(),
        ...getLeadFormFields(),
        ...getGamificationFields(),
        ...getPrivacyFields(),
        ...getActionButtonFields(),
        ...getPrivacyGamificationDemoFields(),
        ...getAutoRedirectFields(),
        ...getYandexMetricFields(BLOCK.matching),
        ...getSdkIntegrationFields(),
    },
    [BLOCK.audio]: {
        [SCHEMA_KEY.url]: {
            label: i18n.t(''),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
            payload: {
                isCanDelete: false,
                contentType: ASSET_CONTENT_TYPE.audio,
            },
        },
        [SCHEMA_KEY.isLoop]: {
            label: i18n.t('Loop'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.isAutoPlay]: {
            label: i18n.t('Auto play'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.isMuted]: {
            label: i18n.t('Muted'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.progressJumpSteps]: {
            label: i18n.t('Progress jump steps'),
            type: 'object',
            default: { backward: 10, forward: 10 },
        },
        [SCHEMA_KEY.firstLineBarControls]: {
            label: i18n.t('First line bar controls'),
            type: 'array',
            default: [
                AUDIO_PLAYER_CONTROLS.PLAY,
                AUDIO_PLAYER_CONTROLS.REWIND,
                AUDIO_PLAYER_CONTROLS.CURRENT_TIME,
                AUDIO_PLAYER_CONTROLS.PROGRESS_BAR,
                AUDIO_PLAYER_CONTROLS.DURATION,
                AUDIO_PLAYER_CONTROLS.VOLUME_BAR,
                AUDIO_PLAYER_CONTROLS.SPEED,
            ],
        },
        [SCHEMA_KEY.secondLineBarControls]: {
            label: i18n.t('Second line bar controls'),
            type: 'array',
            default: [],
        },
    },
    [BLOCK.embedIframe]: {
        [SCHEMA_KEY.embedCode]: {
            label: i18n.t('Embed code'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.TextArea,
        },
        [SCHEMA_KEY.height]: {
            label: i18n.t('Iframe height'),
            type: 'number',
            default: 300,
            min: 300,
            max: 3000,
            control: CONTROLS_ENUM.TextInput,
        },
    },
    [BLOCK.question]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#2990fb',
            control: CONTROLS_ENUM.ColorPicker,
        },
    },
    [BLOCK.wheelSpin]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Main color'),
            type: 'string',
            default: '#2990fb',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.colorScheme]: {
            label: i18n.t('Color scheme'),
            type: 'string',
            default: 'MONOCHROMATIC',
            control: CONTROLS_ENUM.Select,
            payload: {
                items: COLOR_SCHEMES,
                getOptionFromValue: value => {
                    const item = COLOR_SCHEMES.find(option => option.value === value)
                    if (!item) return null

                    return {
                        value,
                        label: item.label,
                    }
                },
                setValueFromOption: option => option.value,
            },
        },
        [SCHEMA_KEY.fontFamily]: {
            label: i18n.t('Font family'),
            type: 'string',
            default: 'Montserrat',
            control: CONTROLS_ENUM.Select,
            payload: {
                items: getRemixFontsList(true),
                useValueAsFontFamily: true,
                getOptionFromValue: value => ({
                    value,
                    label: value,
                }),
                setValueFromOption: option => option.value,
            },
        },
        ...getRestartFields(),
        ...getLeadFormFields(),
        ...getActionButtonFields(),
        ...getAutoRedirectFields(),
        ...getYandexMetricFields(BLOCK.wheelSpin),
        ...getSdkIntegrationFields(),
    },
    [BLOCK.crossword]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#2990fb',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.fontFamily]: {
            label: i18n.t('Font family'),
            type: 'string',
            default: 'Roboto',
            control: CONTROLS_ENUM.Select,
            payload: {
                items: getRemixFontsList(true),
                useValueAsFontFamily: true,
                getOptionFromValue: value => ({
                    value,
                    label: value,
                }),
                setValueFromOption: option => option.value,
            },
        },
        [SCHEMA_KEY.letterColor]: {
            label: i18n.t('Letter color'),
            type: 'string',
            default: '#3c3c3c',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.isMarkCorrectLetters]: {
            label: i18n.t('Mark correct letters'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.isMarkCorrectWords]: {
            label: i18n.t('Mark correct words'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.correctLetterColor]: {
            label: i18n.t('Correct letter color'),
            type: 'string',
            default: '#65bb5a',
            control: CONTROLS_ENUM.ColorPicker,
        },
        ...getRestartFields(),
        ...getLeadFormFields(),
        ...getGamificationFields(),
        ...getPrivacyFields(),
        ...getPrivacyGamificationDemoFields(),
        ...getActionButtonFields(),
        ...getAutoRedirectFields(),
        ...getYandexMetricFields(BLOCK.crossword),
        ...getSdkIntegrationFields(),
    },
    [BLOCK.slidingPuzzle]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#2990fb',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.fontFamily]: {
            label: i18n.t('Font family'),
            type: 'string',
            default: 'Roboto',
            control: CONTROLS_ENUM.Select,
            payload: {
                items: getRemixFontsList(true),
                useValueAsFontFamily: true,
                getOptionFromValue: value => ({
                    value,
                    label: value,
                }),
                setValueFromOption: option => option.value,
            },
        },
        [SCHEMA_KEY.isHighlightCorrect]: {
            label: i18n.t('Mark correct tiles'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.correctColor]: {
            label: i18n.t('Correct tile color'),
            type: 'string',
            default: '#65bb5a',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.isShowShuffleButton]: {
            label: i18n.t('Show shuffle button'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        ...getRestartFields(),
        ...getLeadFormFields(),
        ...getGamificationFields(),
        ...getPrivacyFields(),
        ...getPrivacyGamificationDemoFields(),
        ...getActionButtonFields(),
        ...getAutoRedirectFields(),
        ...getYandexMetricFields(BLOCK.slidingPuzzle),
        ...getSdkIntegrationFields(),
    },
    [BLOCK.rankBattle]: {
        [SCHEMA_KEY.struct]: {
            type: 'object',
            default: {},
        },
        [SCHEMA_KEY.colorTheme]: {
            label: i18n.t('Color theme'),
            type: 'string',
            default: '#2990fb',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.isOneTimeVote]: {
            label: i18n.t('User can vote one time only'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.fontFamily]: {
            label: i18n.t('Font family'),
            type: 'string',
            default: 'Roboto',
            control: CONTROLS_ENUM.Select,
            payload: {
                items: getRemixFontsList(true),
                useValueAsFontFamily: true,
                getOptionFromValue: value => ({
                    value,
                    label: value,
                }),
                setValueFromOption: option => option.value,
            },
        },
        ...getAutoRedirectFields(i18n.t('Redirect after voting')),
        ...getYandexMetricFields(BLOCK.personalityQuiz),
    },
    [BLOCK.cover]: {
        [SCHEMA_KEY.text]: {
            type: 'string',
            default: `<p class="ql-align-center"><span class="ql-font-Roboto" style="font-size: 36px; line-height: 1.5; color: rgb(255, 255, 255);">${i18n.t(
                'Type your text there...',
            )}</span></p>`,
            minLength: 0,
            maxLength: 16000,
        },
        [SCHEMA_KEY.imageProportions]: {
            label: i18n.t('Image proportions'),
            type: 'string',
            default: '3|2',
            control: CONTROLS_ENUM.Select,
            payload: {
                items: IMAGE_PROPORTIONS,
                getOptionFromValue: value => {
                    const item = IMAGE_PROPORTIONS.find(option => option.value === value)
                    if (!item) return null

                    return {
                        value,
                        label: item.label,
                    }
                },
                setValueFromOption: option => option.value,
            },
        },
        [SCHEMA_KEY.imageUrl]: {
            label: i18n.t('Image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
            payload: {
                isCanDelete: false,
            },
        },
        [SCHEMA_KEY.darkenBackground]: {
            label: `${i18n.t('Darken background')}, %`,
            type: 'number',
            default: 20,
            min: 0,
            max: 100,
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.isShowLogotype]: {
            label: i18n.t('Logotype'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.logotypeUrl]: {
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
            payload: {
                isCanDelete: false,
            },
        },
        [SCHEMA_KEY.logotypeScale]: {
            label: `${i18n.t('Scale')}, %`,
            type: 'number',
            default: 20,
            min: 1,
            max: 100,
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.logotypePosition]: {
            label: i18n.t('Position'),
            type: 'string',
            default: 'center',
            control: CONTROLS_ENUM.RadioButton,
            payload: {
                flexDirection: 'row',
                items: EXTENDED_POSITIONS,
                getOptionFromValue: value => {
                    const item = EXTENDED_POSITIONS.find(el => el.value === value)
                    if (!item) return null

                    return {
                        value,
                        label: item.label,
                    }
                },
                setValueFromOption: option => option.value,
            },
        },
        [SCHEMA_KEY.isShowButton]: {
            label: i18n.t('Button'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.buttonText]: {
            type: 'string',
            default: `<p class="ql-align-center"><span class="ql-font-Roboto" style="font-size: 20px; line-height: 1.5; color: rgb(255, 255, 255);">${i18n.t(
                'Click',
            )}</span></p>`,
            minLength: 0,
            maxLength: 2000,
        },
        [SCHEMA_KEY.buttonUrl]: {
            label: i18n.t('Link'),
            type: 'string',
            default: 'https://interacty.me',
            control: CONTROLS_ENUM.InputWithModal,
            payload: {
                modalName: MODAL_NAMES.selectInternalPageModal,
            },
        },
        [SCHEMA_KEY.buttonBackgroundColor]: {
            label: i18n.t('Background color'),
            type: 'string',
            default: '#2990fb',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.buttonBorderRadius]: {
            label: `${i18n.t('Border radius')}, ${i18n.t('px')}`,
            type: 'number',
            default: 4,
            min: 0,
            max: 99,
            control: CONTROLS_ENUM.TextInput,
        },
        [SCHEMA_KEY.buttonPosition]: {
            label: i18n.t('Position'),
            type: 'string',
            default: 'center',
            control: CONTROLS_ENUM.RadioButton,
            payload: {
                flexDirection: 'row',
                items: EXTENDED_POSITIONS,
                getOptionFromValue: value => {
                    const item = EXTENDED_POSITIONS.find(el => el.value === value)
                    if (!item) return null

                    return {
                        value,
                        label: item.label,
                    }
                },
                setValueFromOption: option => option.value,
            },
        },
    },
    [BLOCK.textAndImage]: {
        [SCHEMA_KEY.isTransparentBackground]: {
            label: i18n.t('Transparent background'),
            type: 'boolean',
            default: true,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.backgroundColor]: {
            label: i18n.t('Background color'),
            type: 'string',
            default: '#fff',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.text]: {
            type: 'string',
            default: `<p class="ql-align-left"><span class="ql-font-Roboto" style="font-size: 24px; line-height: 1.5; color: rgb(60, 60, 60);">${i18n.t(
                'Type your text there...',
            )}</span></p>`,
            minLength: 0,
            maxLength: 16000,
        },
        [SCHEMA_KEY.imageUrl]: {
            label: i18n.t('Image'),
            type: 'string',
            default: '',
            control: CONTROLS_ENUM.UploadButton,
            payload: {
                isCanDelete: false,
            },
        },
        [SCHEMA_KEY.textPosition]: {
            label: i18n.t('Text position'),
            type: 'string',
            default: 'right',
            control: CONTROLS_ENUM.RadioButton,
            payload: {
                flexDirection: 'row',
                items: POSITIONS,
                getOptionFromValue: value => {
                    const item = POSITIONS.find(el => el.value === value)
                    if (!item) return null

                    return {
                        value,
                        label: item.label,
                    }
                },
                setValueFromOption: option => option.value,
            },
        },
        [SCHEMA_KEY.isShowButton]: {
            label: i18n.t('Button'),
            type: 'boolean',
            default: false,
            control: CONTROLS_ENUM.Checkbox,
        },
        [SCHEMA_KEY.buttonText]: {
            type: 'string',
            default: `<p class="ql-align-center"><span class="ql-font-Roboto" style="font-size: 20px; line-height: 1.5; color: rgb(255, 255, 255);">${i18n.t(
                'Click',
            )}</span></p>`,
            minLength: 0,
            maxLength: 2000,
        },
        [SCHEMA_KEY.buttonUrl]: {
            label: i18n.t('Link'),
            type: 'string',
            default: 'https://interacty.me',
            control: CONTROLS_ENUM.InputWithModal,
            payload: {
                modalName: MODAL_NAMES.selectInternalPageModal,
            },
        },
        [SCHEMA_KEY.buttonBackgroundColor]: {
            label: i18n.t('Background color'),
            type: 'string',
            default: '#2990fb',
            control: CONTROLS_ENUM.ColorPicker,
        },
        [SCHEMA_KEY.buttonBorderRadius]: {
            label: i18n.t('Border radius'),
            type: 'number',
            default: 4,
            min: 0,
            max: 99,
            control: CONTROLS_ENUM.TextInput,
        },
    },
}
