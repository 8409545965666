import React, { useState, useEffect } from 'react'

import { CONTROLS } from '../../../controls'

const useControls = ({ isMultiplayerGame, target, typeIdentifier, blockData, onUpdate, integrations }) => {
    const [controls, setControls] = useState([])

    useEffect(() => {
        const _controls = typeIdentifier ? CONTROLS[target][typeIdentifier] : CONTROLS[target]

        if (!_controls) return []

        setControls(
            _controls.map(group => ({
                ...group,
                isVisible: group.isVisible ? group.isVisible({ blockData, isMultiplayerGame, integrations }) : true,
                isDisabled: group.isDisabled ? group.isDisabled(blockData) : false,
                values: group.values.map(value => ({
                    ...value,
                    isVisible: value.isVisible ? value.isVisible({ blockData, isMultiplayerGame, integrations }) : true,
                    isDisabled: value.isDisabled ? value.isDisabled(blockData) : false,
                    fieldValue: value.getValue ? value.getValue(blockData, onUpdate) : undefined,
                })),
                tooltipText: typeof group.tooltipText === 'function' ? group.tooltipText() : group.tooltipText,
            })),
        )
    }, [isMultiplayerGame, target, typeIdentifier, blockData])

    return { controls }
}

export default useControls
