import isJSON from 'validator/lib/isJSON'
import isEmail from 'validator/lib/isEmail'

export const isValue = value => {
    try {
        return value !== void 0 && value !== null
    } catch (_) {
        return false
    }
}

export const UTILS__VALIDATOR__is_email = data => {
    return isEmail(data)
}
export const UTILS__VALIDATOR__is_value = data => {
    return isValue(data)
}
export const UTILS__VALIDATOR__is_json = data => {
    return isJSON(data)
}
export const UTILS__VALIDATOR__validate_email = email => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regexp.test(String(email).toLowerCase())
}

export const UTILS__VALIDATOR__validate_url = url => {
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(String(url))
}

export const isInn = value => {
    if (
        typeof value !== 'string' ||
        (value.length !== 10 && value.length !== 12) ||
        value.split('').some(symbol => isNaN(Number(symbol)))
    )
        return false

    if (value.length === 10) {
        return (
            Number(value[9]) ===
            (value
                .split('')
                .slice(0, -1)
                .reduce((summ, symbol, index) => [2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ, 0) %
                11) %
                10
        )
    } else if (value.length === 12) {
        let checkSumOne =
            (value
                .split('')
                .slice(0, -2)
                .reduce((summ, symbol, index) => [7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ, 0) %
                11) %
            10

        let checkSumTwo =
            (value
                .split('')
                .slice(0, -1)
                .reduce((summ, symbol, index) => [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ, 0) %
                11) %
            10

        return checkSumOne === Number(value[10]) && checkSumTwo === Number(value[11])
    }
}
